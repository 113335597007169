import React, {Component} from 'react';
import './App.css';
import fox from "./audioclips/fox.mp3";
import gewitter from "./audioclips/gewitter.mp3";
import nachtigal from "./audioclips/nachtigal.mp3";
import crickets from "./audioclips/crickets.mp3";
import gebuesch from "./audioclips/gebuesch.mp3";
import regen from "./audioclips/regen.mp3";

import logo from "./images/logo.png";
import {Howl, Howler} from "howler";
import ReactDOM from 'react-dom';
import Button from '@material-ui/core/Button';



const audioClips =  [
  {sound: fox, label: "Geräusch 1"},
  {sound: gebuesch, label: "Geräusch 2"},
  {sound: nachtigal, label: "Geräusch 3"},
  {sound: crickets, label: "Geräusch 4"},
  {sound: gewitter, label: "Geräusch 5"},
  {sound: regen, label: "Geräusch 6"}

]

class App extends Component {
  SoundPlay = (src) => {
    const sound = new Howl({
      src
    })
    sound.play();
  }

RenderButtonAndSound = () =>  {
  return audioClips.map ((soundObj, index) => {
    return(
    <Button variant="contained" color="primary"  key={index} onClick={() => this.SoundPlay(soundObj.sound)} > 
    {soundObj.label} 
    <h1></h1>
    </Button>
    )
  })
} 
TestButtons = () => {

  return (
    <Button variant="contained">Default</Button>
  )
}
  render(){
    Howler.volume(1.0);
  return (
   
    <div className="App">
     
      <header>
        
      <img src={logo} width="200" height="200"></img>
        <p>DPSG Tuttlingen</p></header>
      <h2> Willkommen ! </h2>
      <h1></h1>
      <h3>Hör dir die Töne an und gib auf deinem Aufgabenzettel an, was man nachts im Wald hören kann
        <h4></h4>
      </h3>



 {this.RenderButtonAndSound()}



 
    </div>
  );
}
}
export default App; 
